import { lazy } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { AuthenticationGuard, StateGuard } from 'pages/Guards';
import { USER_ROLES } from 'constants';
import CreatePrintableGiftCardsPreview from 'pages/printable-giftcards/CreatePrintableGiftCardsPreview';

// render - dashboard
const Businesses = Loadable(lazy(() => import('pages/businesses')));
const CreateBusiness = Loadable(lazy(() => import('pages/businesses/CreateBusiness')));
const UpdateBusiness = Loadable(lazy(() => import('pages/businesses/UpdateBusiness')));
const CreateBusinessUser = Loadable(lazy(() => import('pages/businesses/CreateBusinessUser')));
const ViewBusiness = Loadable(lazy(() => import('pages/businesses/ViewBusiness')));
const Promotions = Loadable(lazy(() => import('pages/promotions')));
const SetPromotionBranch = Loadable(lazy(() => import('pages/branches/SetPromotionBranches')));
const CreatePromotion = Loadable(lazy(() => import('pages/promotions/CreatePromotion')));
const UpdatePromotion = Loadable(lazy(() => import('pages/promotions/UpdatePromotion')));
const ViewPromotion = Loadable(lazy(() => import('pages/promotions/ViewPromotion')));
const PromotionsCategories = Loadable(lazy(() => import('pages/promotions/categories')));
const CategoryPromotions = Loadable(lazy(() => import('pages/promotions/categories/CategoryPromotions')));
const BranchPromotions = Loadable(lazy(() => import('pages/branches/BranchPromotions')));
const CreatePromotionsCategory = Loadable(lazy(() => import('pages/promotions/categories/CreateCategory')));
const SetPromotionCategory = Loadable(lazy(() => import('pages/promotions/categories/SetPromotionCategory')));
const MembershipPackages = Loadable(lazy(() => import('pages/memberships')));
const CreateMembershipPackage = Loadable(lazy(() => import('pages/memberships/CreateMembershipPackage')));
const GiftCards = Loadable(lazy(() => import('pages/giftcards')));
const OrderedGiftCards = Loadable(lazy(() => import('pages/giftcards/Ordered')));
const OrderedDonations = Loadable(lazy(() => import('pages/charities/Ordered')));
const CreateGiftCard = Loadable(lazy(() => import('pages/giftcards/CreateGiftCard')));
const UpdateGiftCard = Loadable(lazy(() => import('pages/giftcards/UpdateGiftCard')));
const ViewGiftCard = Loadable(lazy(() => import('pages/giftcards/ViewGiftCard')));

const Tickets = Loadable(lazy(() => import('pages/tickets')));
const OrderedTickets = Loadable(lazy(() => import('pages/tickets/Ordered')));
const CreateTicket = Loadable(lazy(() => import('pages/tickets/CreateTicket')));
const UpdateTicket = Loadable(lazy(() => import('pages/tickets/UpdateTicket')));
const ViewTicket = Loadable(lazy(() => import('pages/tickets/ViewTicket')));

const CreateBranch = Loadable(lazy(() => import('pages/branches/CreateBranch')));
const Branches = Loadable(lazy(() => import('pages/branches')));
const LoyaltySchemes = Loadable(lazy(() => import('pages/loyalty-schemes')));
const CreateLoyaltyScheme = Loadable(lazy(() => import('pages/loyalty-schemes/CreateLoyaltyScheme')));
const LoyaltySchemesCreateTrigger = Loadable(lazy(() => import('pages/loyalty-schemes/CreateTrigger')));
const UpdateLoyaltyScheme = Loadable(lazy(() => import('pages/loyalty-schemes/UpdateLoyaltyScheme')));
const ViewLoyaltyScheme = Loadable(lazy(() => import('pages/loyalty-schemes/ViewLoyaltyScheme')));
const PushNotification = Loadable(lazy(() => import('pages/notifications')));
const Reports = Loadable(lazy(() => import('pages/reports')));
const Charities = Loadable(lazy(() => import('pages/charities')));
const CreateCharity = Loadable(lazy(() => import('pages/charities/CreateCharity')));
const UpdateCharity = Loadable(lazy(() => import('pages/charities/UpdateCharity')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = ({ isLoggedIn, userRole }) => {
    const location = useLocation();

    const isNotContentManager = userRole !== USER_ROLES.CONTENT_MANAGER;

    return {
        path: '/',
        element: (
            <AuthenticationGuard isLoggedIn={isLoggedIn}>
                <MainLayout />
            </AuthenticationGuard>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="businesses" />
            },
            {
                path: '/businesses',
                element: <Businesses />
            },
            {
                path: '/businesses/create-business',
                element: <CreateBusiness />
            },
            {
                path: '/businesses/edit/:business',
                element: (
                    <StateGuard state={location.state} navigateTo="/businesses">
                        <UpdateBusiness />
                    </StateGuard>
                )
            },
            {
                path: '/businesses/create-business-user',
                element: <CreateBusinessUser />
            },
            {
                path: '/businesses/:business',
                element: <ViewBusiness />
            },
            {
                path: '/promotions',
                element: <Promotions />
            },
            {
                path: '/promotions/categories',
                element: <PromotionsCategories />
            },
            {
                path: '/promotions/create-category',
                element: <CreatePromotionsCategory />
            },
            {
                path: '/promotions/set-promotion-category',
                element: <SetPromotionCategory />
            },
            {
                path: '/businesses/branches/create',
                element: <CreateBranch />
            },
            {
                path: '/businesses/:id/branches/set-promotion-branches',
                element: <SetPromotionBranch />
            },
            {
                path: '/promotions/categories/:category',
                element: <CategoryPromotions />
            },
            {
                path: '/businesses/:id/branches/:branch',
                element: <BranchPromotions />
            },
            {
                path: '/promotions/create-promotion',
                element: <CreatePromotion />
            },
            {
                path: '/promotions/edit/:promotion',
                element: (
                    <StateGuard state={location.state} navigateTo="/promotions">
                        <UpdatePromotion />
                    </StateGuard>
                )
            },
            {
                path: '/promotions/:promotion',
                element: <ViewPromotion />
            },

            {
                path: '/memberships',
                element: <MembershipPackages />
            },
            {
                path: '/memberships/create-membership-package',
                element: <CreateMembershipPackage />
            },
            {
                path: '/giftcards',
                element: <GiftCards />
            },

            {
                path: '/giftcards/create-giftcard',
                element: <CreateGiftCard />
            },
            {
                path: '/giftcards/edit/:giftCardId',
                element: (
                    <StateGuard state={location.state} navigateTo="/giftcards">
                        <UpdateGiftCard />
                    </StateGuard>
                )
            },
            {
                path: '/giftcards/:giftCardId',
                element: <ViewGiftCard />
            },

            {
                path: '/tickets',
                element: <Tickets />
            },

            {
                path: '/tickets/create-ticket',
                element: <CreateTicket />
            },
            {
                path: '/tickets/edit/:ticketId',
                element: (
                    <StateGuard state={location.state} navigateTo="/tickets">
                        <UpdateTicket />
                    </StateGuard>
                )
            },
            {
                path: '/tickets/:ticketId',
                element: <ViewTicket />
            },

            {
                path: '/branches',
                element: <Branches />
            },
            {
                path: '/loyalty-schemes',
                element: <LoyaltySchemes />
            },
            {
                path: '/loyalty-schemes/create-scheme',
                element: <CreateLoyaltyScheme />
            },
            {
                path: '/loyalty-schemes/create-trigger',
                element: (
                    <StateGuard state={location.state} navigateTo="/loyalty-schemes">
                        <LoyaltySchemesCreateTrigger />
                    </StateGuard>
                )
            },
            {
                path: '/loyalty-schemes/edit/:loyaltySchemeId',
                element: (
                    <StateGuard state={location.state} navigateTo="/loyalty-schemes">
                        <UpdateLoyaltyScheme />
                    </StateGuard>
                )
            },
            {
                path: '/loyalty-schemes/:loyaltySchemeId',
                element: <ViewLoyaltyScheme />
            },
            {
                path: '/reports',
                children: [
                    {
                        path: '',
                        element: <Reports />
                    }
                ]
            },
            ...(isNotContentManager
                ? [
                      {
                          path: '/orders',
                          element: <Navigate to="/orders/giftcards" replace />
                      },
                      {
                          path: '/orders/giftcards',
                          element: <OrderedGiftCards />
                      },
                      {
                          path: '/orders/tickets',
                          element: <OrderedTickets />
                      },
                      {
                          path: '/orders/donations',
                          element: <OrderedDonations />
                      },
                      {
                          path: '/push-notifications',
                          element: <PushNotification />
                      }
                  ]
                : []),
            {
                path: '/charities',
                element: <Charities />
            },
            {
                path: '/charities/create-charity',
                element: <CreateCharity />
            },
            {
                path: '/charities/edit/:charity',
                element: (
                    <StateGuard state={location.state} navigateTo="/charities">
                        <UpdateCharity />
                    </StateGuard>
                )
            },
            {
                path: '/printable-giftcards/preview/create',
                element: <CreatePrintableGiftCardsPreview />
            }
        ]
    };
};

export default MainRoutes;
