import { useState } from 'react';
import useAxios from './useAxios';
import { MESSAGES } from 'constants';

function getErrorOrDefault(err) {
    return err.response
        ? typeof err.response.data == 'string'
            ? MESSAGES[err.response.data] ?? 'Something went wrong'
            : JSON.stringify(err.response.data)
        : err?.message ?? 'Something went wrong';
}

export const REQUEST_TYPES = { PATCH: 'PATCH', DELETE: 'DELETE', POST: 'POST' };
export default function useMutation(route, isPatchRequest, requestType) {
    const axios = useAxios();
    const [error, setError] = useState(null);

    const clearError = () => setError(null);

    const mutate = async (body) => {
        setError(null);
        try {
            let res = null;
            if (isPatchRequest || requestType === REQUEST_TYPES.PATCH) {
                res = await axios.patch(route, body);
            } else if (requestType === REQUEST_TYPES.DELETE) {
                res = await axios.delete(route);
            } else {
                res = await axios.post(route, body);
            }
            return Promise.resolve(res);
        } catch (err) {
            const error = getErrorOrDefault(err);
            setError(error);
            return Promise.reject(err);
        }
    };

    return { mutate, error, clearError };
}
