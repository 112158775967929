import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshAccessToken } from 'services/auth';
import { login, logout } from 'store/reducers/auth';

export default function useAxios() {
    const { accessToken, refreshToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_LDC_API_BASEURL
    });

    useEffect(() => {
        createAuthorizationInterceptors({ axiosInstance, accessToken, refreshToken, dispatch });
    }, [accessToken, axiosInstance, dispatch, refreshToken]);

    return axiosInstance;
}

const createAuthorizationInterceptors = ({ axiosInstance, accessToken, refreshToken, dispatch }) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers = {
                Authorization: `Bearer ${accessToken}`
            };
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if (!error.response) {
                return Promise.reject(error);
            }

            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const tokens = await refreshAccessToken({ refreshToken });
                    originalRequest.headers.Authorization = `Bearer ${tokens.accessToken}`;

                    dispatch(login(tokens));

                    return axios(originalRequest);
                } catch (err) {
                    dispatch(logout());

                    window.location.href = '/login';

                    return Promise.reject(err);
                }
            }
            return Promise.reject(error);
        }
    );
};
