// types
import { createSlice } from '@reduxjs/toolkit';
import { decodeToken } from 'helpers';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

// initial state
const initialState = {
    accessToken: '',
    refreshToken: '',
    userRole: null
};

// ==============================|| SLICE - MENU ||============================== //

const authPersistConfig = {
    key: 'auth',
    storage: localStorage
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            const userRole = decodeToken(action.payload.accessToken).role;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.userRole = userRole;
        },

        logout(state) {
            state.accessToken = '';
            state.refreshToken = '';
            state.userRole = null;
        }
    }
});

export default persistReducer(authPersistConfig, auth.reducer);

export const { login, logout } = auth.actions;
