import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function CardFooter({ isSubmitting, buttonText = 'Create' }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', px: 3, py: 1 }}>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                <span>{buttonText}</span>
            </LoadingButton>
        </Box>
    );
}

CardFooter.propTypes = {
    isSubmitting: PropTypes.bool,
    buttonText: PropTypes.string
};
