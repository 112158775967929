import { Box, InputLabel, OutlinedInput, FormHelperText, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';

export default function Description({ values, handleChange, handleBlur, errors, touched }) {
    return (
        <Box sx={{ p: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="description-en">Description EN</InputLabel>
                        <OutlinedInput
                            id="description-en"
                            type="text"
                            value={values.descriptionEn}
                            name="descriptionEn"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Description"
                            multiline
                            minRows={4}
                            fullWidth
                            error={Boolean(touched.descriptionEn && errors.descriptionEn)}
                        />
                        {touched.descriptionEn && errors.descriptionEn && <FormHelperText error>{errors.descriptionEn}</FormHelperText>}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="description-tr">Description TR</InputLabel>
                        <OutlinedInput
                            id="description-tr"
                            type="text"
                            value={values.descriptionTr ?? ''}
                            name="descriptionTr"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Description"
                            multiline
                            minRows={4}
                            fullWidth
                            error={Boolean(touched.descriptionTr && errors.descriptionTr)}
                        />
                        {touched.descriptionTr && errors.descriptionTr && <FormHelperText error>{errors.descriptionTr}</FormHelperText>}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="description-el">Description EL</InputLabel>
                        <OutlinedInput
                            id="description-el"
                            type="text"
                            value={values.descriptionEl ?? ''}
                            name="descriptionEl"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Description"
                            multiline
                            minRows={4}
                            fullWidth
                            error={Boolean(touched.descriptionEl && errors.descriptionEl)}
                        />
                        {touched.descriptionEl && errors.descriptionEl && <FormHelperText error>{errors.descriptionEl}</FormHelperText>}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

Description.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object
};
