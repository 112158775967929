// material-ui
import { Box } from '@mui/material';

// project import
import NavItem from './NavItem';
import menuItems from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const { userRole } = useSelector((state) => state.auth);

    const accessibleMenuItems = menuItems.filter((item) => !item?.inaccessibleRoles?.includes(userRole));

    return (
        <Box sx={{ pt: 2 }}>
            {accessibleMenuItems.map((item) => {
                return <NavItem key={item.id} item={item} />;
            })}
        </Box>
    );
};

export default Navigation;
