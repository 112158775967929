import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const location = useLocation();
    const { drawerOpen, openItem } = menu;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [currentBasePath, setCurrentBasePath] = useState('');
    const filterMenuItems = item?.children?.filter((child) => child.isSubmenu);
    const submenu = filterMenuItems && filterMenuItems.length > 0 ? filterMenuItems : undefined;
    let forwardedComponent = forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} />);
    forwardedComponent.displayName = 'Forwarded Nav Item Component';

    let listItemProps = { component: forwardedComponent };

    const itemHandler = (id) => {
        if (submenu) {
            if (isDropdownOpen) {
                dispatch(activeItem({ openItem: [id] }));
            } else {
                dispatch(activeItem({ openItem: [submenu[0].id] }));
            }
            setIsDropdownOpen(!isDropdownOpen);
        } else {
            dispatch(activeItem({ openItem: [id] }));
        }
    };

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    useEffect(() => {
        const newBasePath = location.pathname;
        setCurrentBasePath(newBasePath);
    }, [location.pathname]);

    // active menu item when location changes

    useEffect(() => {
        const pathname = document.location.pathname.toString();
        if (pathname.startsWith(item.url)) {
            if (submenu && !currentBasePath.startsWith(item.url)) {
                setIsDropdownOpen(true);
            }

            if (pathname === item.url) {
                if (submenu) {
                    dispatch(activeItem({ openItem: [submenu[0].id] }));
                } else {
                    dispatch(activeItem({ openItem: [item.id] }));
                }
            }
        } else {
            setIsDropdownOpen(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBasePath]);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    return (
        <>
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                onClick={() => itemHandler(item.id)}
                selected={isSelected}
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? '28px' : 1.5,
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: 'primary.lighter'
                        },
                        '&.Mui-selected': {
                            bgcolor: 'primary.lighter',
                            borderRight: `2px solid ${theme.palette.primary.main}`,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: 'primary.lighter'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}
            >
                <ListItemText
                    primary={
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {item.title}
                        </Typography>
                    }
                />

                {submenu && <>{isDropdownOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}</>}
            </ListItemButton>

            <Collapse in={isDropdownOpen} timeout="auto" unmountOnExit>
                {submenu && isDropdownOpen && (
                    <List sx={{ pl: 2 }}>
                        {submenu.map((item) => (
                            <NavItem key={item.id} item={item} />
                        ))}
                    </List>
                )}
            </Collapse>
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object
};

export default NavItem;
