// third-party
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

// project import
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production'
});

const { dispatch } = store;

const persistor = persistStore(store);

export { store, dispatch, persistor };
