import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ConfirmationDialogContext } from './context';
import { CloseCircleOutlined } from '@ant-design/icons';
import { red } from '@mui/material/colors';

const ConfirmationDialog = ({ open, message, onClose, onConfirm }) => {
    return (
        <Dialog PaperProps={{ sx: { padding: '2rem', pt: '1rem' } }} open={open} onClose={onClose}>
            <DialogTitle sx={{ fontSize: '2.5rem', mb: '2rem', p: 0 }}>
                <CloseCircleOutlined onClick={onClose} style={{ color: red[500] }} />
            </DialogTitle>
            <DialogContent sx={{ p: '1rem' }}>
                <DialogContentText sx={{ fontSize: '2rem', textAlign: 'center' }}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mt: '2.5rem', p: 0 }}>
                <Button variant="outlined" sx={{ fontSize: '1.5rem', width: '90px', mr: '1rem' }} onClick={onClose} color="primary">
                    No
                </Button>
                <Button
                    sx={{ fontSize: '1.5rem', width: '90px' }}
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    variant="contained"
                    color="primary"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ConfirmationDialogProvider = ({ children }) => {
    const [dialogState, setDialogState] = useState({
        open: false,
        message: '',
        onClose: () => setDialogState({ ...dialogState, open: false }),
        onConfirm: () => {}
    });

    const openConfirmationDialog = (message, onConfirm) => {
        setDialogState({
            open: true,
            message,
            onClose: () => setDialogState({ ...dialogState, open: false }),
            onConfirm
        });
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openConfirmationDialog }}>
            {children}
            {dialogState.open && <ConfirmationDialog {...dialogState} />}
        </ConfirmationDialogContext.Provider>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

// PropTypes for ConfirmationDialogProvider component
ConfirmationDialogProvider.propTypes = {
    children: PropTypes.node.isRequired
};
