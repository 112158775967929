// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from 'store/reducers/auth';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login', { replace: true });
    };

    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Navigation />
            <Button variant="contained" sx={{ mt: '2em', width: '50%', mx: 'auto' }} onClick={handleLogout}>
                Log Out
            </Button>
        </SimpleBar>
    );
};

export default DrawerContent;
