import dayjs from 'dayjs';

export const returnIfNotEmpty = (str) => {
    if (!str || str.trim() === '') return false;
    return str;
};

export const getUniqueItem = (data, field) => {
    if (!data?.map || !data[0]?.[field]) return;
    return data.reduce((acc, item) => {
        if (!acc.some((existingItem) => existingItem[field] === item[field])) {
            acc.push(item);
        }
        return acc;
    }, []);
};

export const formatDate = (dateStr) => {
    if (dateStr) return dayjs(dateStr).format('MM/DD/YYYY h:mm A');
};

export const removeDuplicatesWithComparator = (array, comparator) => {
    const uniqueArray = array.filter((item, index, self) => index === self.findIndex((t) => comparator(t, item)));
    return uniqueArray;
};

export const decodeToken = (accessToken) => {
    const tokenParts = accessToken.split('.');
    if (tokenParts.length !== 3) {
        return {};
    }

    const [, payloadBase64] = tokenParts;
    const payload = JSON.parse(atob(payloadBase64));

    if (!payload || typeof payload !== 'object') {
        return {};
    }

    return payload;
};
