import PropTypes from 'prop-types';
import { Box, OutlinedInput, InputLabel, Grid, Stack, FormHelperText } from '@mui/material';

export default function PromotionDetails({ values, handleBlur, handleChange, errors, touched }) {
    return (
        <Box sx={{ p: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="name-eng">Name ENG</InputLabel>
                        <OutlinedInput
                            id="name-eng"
                            type="text"
                            value={values.nameENG}
                            name="nameENG"
                            sx={{ padding: '8px 5px' }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Name"
                            fullWidth
                            error={Boolean(touched.nameENG && errors.nameENG)}
                        />
                        {touched.nameENG && errors.nameENG && <FormHelperText error>{errors.nameENG}</FormHelperText>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="name-tr">Name TR</InputLabel>
                        <OutlinedInput
                            id="name-tr"
                            type="text"
                            value={values.nameTR ?? ''}
                            name="nameTR"
                            sx={{ padding: '8px 5px' }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Name"
                            fullWidth
                            error={Boolean(touched.nameTR && errors.nameTR)}
                        />
                        {touched.nameTR && errors.nameTR && <FormHelperText error>{errors.nameTR}</FormHelperText>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="name-el">Name EL</InputLabel>
                        <OutlinedInput
                            id="name-el"
                            type="text"
                            value={values.nameEL ?? ''}
                            name="nameEL"
                            sx={{ padding: '8px 5px' }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Name"
                            fullWidth
                            error={Boolean(touched.nameEL && errors.nameEL)}
                        />
                        {touched.nameEL && errors.nameEL && <FormHelperText error>{errors.nameEL}</FormHelperText>}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

PromotionDetails.propTypes = {
    values: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object
};
