import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Box, Button, Grid, Typography } from '@mui/material';
import { EditOutlined } from '@ant-design/icons';

// project imports
import MainCard from '../MainCard';
import navigation from 'menu-items';
import useRoutePattern from 'hooks/useRoutePattern';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = () => {
    const { state } = useLocation();
    const pathname = useRoutePattern();

    const [main, setMain] = useState([]);
    const [item, setItem] = useState();

    // set active item state
    const getCollapse = (menu) => {
        const normalizedPathname = pathname.endsWith('/') ? pathname.slice(0, pathname.length - 1) : pathname;
        setItem(menu);
        setMain((_main) => _main.concat([menu]));
        if (!menu.children) {
            setMain((_main) => _main.slice(0, _main.length - 1));
        }
        menu.children?.map((child) => {
            if (normalizedPathname === menu.url) {
                setMain((_main) => _main.slice(0, _main.length - 1));
            }
            if (normalizedPathname === child.url) {
                if (child.hasDynamicTitle) {
                    setItem(state?.data.breadcrumbs);
                } else {
                    setItem(child);
                }
            }
        });
    };

    useEffect(() => {
        setMain([]);
        const match = navigation.find((item) => pathname.startsWith(item.url));
        if (match) {
            getCollapse(match);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, state?.data?.breadcrumbs?.title]);

    if (item) {
        return (
            <MainCard border={false} sx={{ mb: 2, bgcolor: 'transparent', borderRadius: 0 }} content={false}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <MuiBreadcrumbs aria-label="breadcrumb">
                            <Typography
                                component={Link}
                                to="/businesses"
                                color="textSecondary"
                                variant="h6"
                                sx={{ textDecoration: 'none' }}
                            >
                                Home
                            </Typography>
                            {main.map((item) => (
                                <Typography
                                    key={item.id}
                                    component={Link}
                                    to={item.url}
                                    variant="h6"
                                    sx={{ textDecoration: 'none' }}
                                    color="textSecondary"
                                >
                                    {item.title}
                                </Typography>
                            ))}
                            <Typography variant="subtitle1" color="textPrimary">
                                {item.title}
                            </Typography>
                        </MuiBreadcrumbs>
                    </Grid>
                    <Grid item sx={{ mt: 2, width: '100%' }}>
                        <Box display="flex" justifyContent="space-between" gap={2}>
                            <Typography variant="h2">{item.title}</Typography>
                            {item.type && (
                                <Button
                                    component={Link}
                                    variant="outlined"
                                    startIcon={<EditOutlined />}
                                    sx={{ '& .MuiButton-startIcon': { mr: 2 }, color: '#404D61', borderColor: '#404D61' }}
                                    to={item.href}
                                    state={state}
                                >
                                    Edit {item.type}
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return <></>;
};

export default Breadcrumbs;
