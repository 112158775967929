import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CatchAllRoute from './CatchAllRoute';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { accessToken, userRole } = useSelector((state) => state.auth);

    const isLoggedIn = !!accessToken;

    return useRoutes([MainRoutes({ isLoggedIn, userRole }), LoginRoutes(isLoggedIn), CatchAllRoute]);
}
