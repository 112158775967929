import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';

const NotFound = () => {
    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid
                container
                direction="column"
                sx={{
                    minHeight: '100vh'
                }}
            >
                <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
                    <Logo to="/login" />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
                    >
                        <Grid item>
                            <Typography variant="h1">404</Typography>
                            <Typography>The server could not find the page you are looking for</Typography>
                            <Link to="/businesses">
                                <Typography>Go home</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotFound;
