import NotFound from 'pages/NotFound';
import MinimalLayout from 'layout/MinimalLayout/index';

const CatchAllRoute = {
    path: '*',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default CatchAllRoute;
