import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = (isLoggedIn) => ({
    path: 'login',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            element: isLoggedIn ? <Navigate to="/businesses" /> : <AuthLogin />
        }
    ]
});

export default LoginRoutes;
