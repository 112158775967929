import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_UMS_API_BASEURL + '/auth'
});

export async function makeLoginRequest(email, password) {
    const reqBody = {
        companyId: process.env.REACT_APP_COMPANY_ID,
        email,
        password
    };
    try {
        const res = await axiosInstance.post('login', reqBody);

        if (!res.data.data) {
            return Promise.reject(new Error('No tokens returned'));
        }

        return Promise.resolve({
            accessToken: res.data.data.accessToken,
            refreshToken: res.data.data.refreshToken
        });
    } catch (err) {
        return Promise.reject(err);
    }
}

export const refreshAccessToken = async ({ refreshToken }) => {
    try {
        const res = await axiosInstance.post('refresh-token', {
            refreshToken
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
