import { Box, InputLabel, OutlinedInput, FormHelperText, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';

export default function LDCTextField({
    boxSx = { p: '20px' },
    values,
    handleChange,
    handleBlur,
    errors,
    label,
    touched,
    name,
    id,
    placeholder,
    ...others
}) {
    return (
        <Box sx={boxSx}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor={id}>{label}</InputLabel>
                        <OutlinedInput
                            id={id}
                            type="text"
                            value={values[name]}
                            name={name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            multiline
                            minRows={1}
                            fullWidth
                            placeholder={placeholder ?? label}
                            error={Boolean(touched[name] && errors[name])}
                            {...others}
                        />
                        {touched[name] && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

LDCTextField.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    boxSx: PropTypes.object,
    placeholder: PropTypes.string
};
