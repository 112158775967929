import React, { useState } from 'react';

import {
    Divider,
    CardContent,
    Alert,
    Snackbar,
    IconButton,
    Box,
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
    OutlinedInput
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMutation from 'hooks/useMutation';
import MainCard from 'components/MainCard';
import { API } from 'constants';
import LDCTextField from 'components/LCDTextField';
import Description from 'components/giftcards/Description';
import CardHeader from 'components/CardHeader';
import PromotionDetails from 'components/promotions/PromotionDetails';
import SingleFileUpload from 'components/SingleFileUpload';
import CardFooter from 'components/CardFooter';
import { CloseOutlined } from '@ant-design/icons';

const initialValues = {
    nameENG: '',
    nameTR: '',
    nameEL: '',
    descriptionEn: '',
    descriptionTr: '',
    descriptionEl: '',
    templateKey: '',
    templateImage: null,
    origamiImage: null,
    videoLink: '',
    defaultTemplateMessage: ''
};

const validationSchema = Yup.object().shape({
    nameENG: Yup.string().required('Name En is required'),
    nameTR: Yup.string().required('Name Tr is required'),
    nameEL: Yup.string().required('Name El is required'),
    descriptionEn: Yup.string().required('Description En is required'),
    descriptionTr: Yup.string().required('Description Tr is required'),
    descriptionEl: Yup.string().required('Description El is required'),
    templateKey: Yup.string().required('Template Key is required'),
    templateImage: Yup.mixed().required('Template Image is required'),
    origamiImage: Yup.mixed(),
    videoLink: Yup.string().url('Invalid URL'),
    defaultTemplateMessage: Yup.string().required('Default Template Message is required')
});

export default function CreatePrintableGiftCardsPreview() {
    const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

    const { mutate: CreatePrintableGiftCardsPreview, error, clearError } = useMutation(API.CREATE_PRINTABLE_GIFTCARD_PRIVIEW);

    const handleSubmit = (values, setSubmitting) => {
        const formData = new FormData();

        formData.append('nameEn', values.nameENG);
        formData.append('nameTr', values.nameTR);
        formData.append('nameEl', values.nameEL);
        formData.append('descriptionEn', values.descriptionEn);
        formData.append('descriptionTr', values.descriptionTr);
        formData.append('descriptionEl', values.descriptionEl);
        formData.append('templateKey', values.templateKey);
        formData.append('templateImage', values.templateImage);
        formData.append('origamiImage', values.origamiImage);
        formData.append('videoLink', values.videoLink);
        formData.append('defaultTemplateMessage', values.defaultTemplateMessage);

        CreatePrintableGiftCardsPreview(formData)
            .then(() => {
                setSuccessSnackbarOpen(true);

                setSubmitting(false);
            })
            .catch(() => setSubmitting(false));
    };

    return (
        <>
            <MainCard title="Creates Printable Giftcard Preview" divider contentSX={{ px: 0 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        handleSubmit(values, setSubmitting);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <CardContent>
                                <CardHeader title="Preview Name" />
                                <Divider />
                                <PromotionDetails
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                />

                                <CardHeader title="Description" />
                                <Divider />
                                <Description
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                />

                                <CardHeader title="Template" />
                                <Divider />
                                <LDCTextField
                                    label="Template Key"
                                    name="templateKey"
                                    values={values}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                />

                                <LDCTextField
                                    label="Video Link"
                                    name="videoLink"
                                    values={values}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                />

                                <Stack spacing={1} sx={{ p: '20px' }}>
                                    <InputLabel htmlFor="defaultTemplateMessage">Default Template Message</InputLabel>
                                    <OutlinedInput
                                        id="defaultTemplateMessage"
                                        type="text"
                                        value={values.defaultTemplateMessage}
                                        name="defaultTemplateMessage"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Default Template Message"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        errors={errors}
                                        error={Boolean(touched.defaultTemplateMessage && errors.defaultTemplateMessage)}
                                    />
                                    {touched.defaultTemplateMessage && errors.defaultTemplateMessage && (
                                        <FormHelperText error>{errors.defaultTemplateMessage}</FormHelperText>
                                    )}
                                </Stack>

                                <Box sx={{ p: '20px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Stack spacing={1}>
                                                <InputLabel>Template Image</InputLabel>
                                                <SingleFileUpload
                                                    name="templateImage"
                                                    handleBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    picture={values.templateImage}
                                                />
                                                {touched.templateImage && errors.templateImage && (
                                                    <FormHelperText error>{errors.templateImage}</FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Stack spacing={1}>
                                                <InputLabel>Origami Image (optional)</InputLabel>
                                                <SingleFileUpload
                                                    name="origamiImage"
                                                    handleBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    picture={values.origamiImage}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {error && (
                                    <Alert
                                        severity="error"
                                        action={
                                            <IconButton aria-label="close" color="inherit" size="small" onClick={clearError}>
                                                <CloseOutlined />
                                            </IconButton>
                                        }
                                    >
                                        {error}
                                    </Alert>
                                )}
                            </CardContent>
                            <Divider />
                            <CardFooter isSubmitting={isSubmitting} />
                        </form>
                    )}
                </Formik>
            </MainCard>
            <Snackbar
                open={isSuccessSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSuccessSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert severity="success" onClose={() => setSuccessSnackbarOpen(false)}>
                    Created Preview Successfully
                </Alert>
            </Snackbar>
        </>
    );
}
