import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export function AuthenticationGuard({ isLoggedIn, children }) {
    if (!isLoggedIn) return <Navigate to="/login" />;
    return <>{children}</>;
}

AuthenticationGuard.propTypes = {
    isLoggedIn: PropTypes.bool,
    children: PropTypes.node
};

export function StateGuard({ state, children, navigateTo }) {
    if (!state?.data) return <Navigate to={navigateTo} />;
    return <>{children}</>;
}

StateGuard.propTypes = {
    state: PropTypes.object,
    children: PropTypes.node,
    navigateTo: PropTypes.string
};
