import PropTypes from 'prop-types';
import { Box, IconButton, Button, Grid, Typography } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import { grey } from '@mui/material/colors';

export default function SingleFileUpload({ name, handleBlur, setFieldValue, picture, disabled }) {
    const handleRemove = () => {
        setFieldValue(name, null);
    };

    const handleChange = (e) => {
        if (e.target.files.length) {
            setFieldValue(name, e.target.files[0]);
        }
    };

    return (
        <Grid container spacing={0} sx={{ height: '4em' }}>
            <Grid item xs={8}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: `1px solid ${grey[300]}`,
                        borderRadius: '4px 0 0 4px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        px: 3
                    }}
                    bgcolor={disabled ? '#f0f0f0' : 'initial'}
                >
                    <Typography
                        sx={{
                            fontWeight: picture != null ? 'bold' : 'normal',
                            opacity: disabled ? 0.5 : 1
                        }}
                    >
                        {picture?.name ?? 'No file selected'}
                    </Typography>
                    {picture != null ? (
                        <IconButton onClick={handleRemove}>
                            <CloseOutlined />
                        </IconButton>
                    ) : null}
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Button
                    sx={{ height: '100%', width: '100%', borderRadius: '0 4px 4px 0' }}
                    variant="contained"
                    component="label"
                    disabled={disabled}
                >
                    Add File
                    <input
                        name={name}
                        hidden
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onClick={(e) => (e.target.value = null)}
                        type="file"
                        disabled={disabled}
                    />
                </Button>
            </Grid>
        </Grid>
    );
}

SingleFileUpload.propTypes = {
    name: PropTypes.string,
    picture: PropTypes.object,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    disabled: PropTypes.bool
};
