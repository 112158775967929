// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { ConfirmationDialogProvider } from 'components/confirmation-dialog/ConfirmationDialog';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
    <ThemeCustomization>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
            <ConfirmationDialogProvider>
                <ScrollTop>
                    <Routes />
                </ScrollTop>
            </ConfirmationDialogProvider>
        </LocalizationProvider>
    </ThemeCustomization>
);

export default App;
