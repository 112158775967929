import PropTypes from 'prop-types';
import { CardHeader as MuiCardHeader } from '@mui/material';

const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

export default function CardHeader({ title }) {
    return <MuiCardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} />;
}

CardHeader.propTypes = {
    title: PropTypes.string
};
